import { createSvgIcon } from "@mui/material";

const TelescopeIcon = createSvgIcon(
  <g transform="translate(-6.3, -2.5) scale(0.05168)">
    <path d="M295.2,445c-1.6,3.5-0.2,7.7,3.3,9.3c1,0.5,2,0.7,3,0.7c2.6,0,5.1-1.5,6.3-4l61-129.1c0.1,0,0.1,0,0.2,0.1v126c0,3.9,3.1,7,7,7s7-3.1,7-7v-126c0.1,0,0.1,0,0.2-0.1l61,129.1c1.2,2.5,3.7,4,6.3,4c1,0,2-0.2,3-0.7c3.5-1.7,5-5.8,3.3-9.3l-60.7-128.5c10-6.6,16.3-17.8,16.3-30.3c0-20.1-16.3-36.4-36.4-36.4c-20,0-36.3,16.3-36.3,36.4c0,12.4,6.2,23.7,16.2,30.3L295.2,445z" />
    <path d="M192.7,287.4c-12.5,6.5-17.3,21.8-10.8,34.2l7.5,14.4c4.2,8,12,12.8,20.3,13.5c4.7,0.4,9.6-0.4,14-2.7l32.9-17.2c-1-1.4-1.9-2.9-2.8-4.5l-26.1-50.1c-0.8-1.6-1.4-3.2-2-4.9L192.7,287.4L192.7,287.4z" />
    <path d="M240.1,268.6c-3.2-6-3.7-12.9-1.8-19.4c2.1-6.5,6.5-11.8,12.5-14.9l66-34.5c0.8,3.3,1.9,6.4,3.4,9.4l21.7,41.5c-9.3,8.2-15.7,19.7-16.9,33c-1,10.6,1.4,20.9,6.5,29.6l-31,16.2c-4.4,2.3-9.3,3.2-14,2.7c-8.4-0.8-16.1-5.6-20.3-13.6L240.1,268.6L240.1,268.6z" />
    <path d="M484.2,128.4c-1.8-0.2-3.5-0.1-5.3-0.1l39.3,75.3c5-6.8,8.2-14.9,8.9-23.7C529.4,153.8,510.2,130.7,484.2,128.4L484.2,128.4z" />
    <path d="M330,188.8c0.8-8.4,5.5-16.2,13.5-20.3l104.2-54.4c1.7-0.9,3.6-1,5.4-0.5c1.8,0.6,3.2,1.8,4.1,3.5l57.6,110.3c0.8,1.6,1,3.5,0.5,5.3c-0.6,1.8-1.8,3.3-3.5,4.1L425.1,282c-2.9-23-21.5-41.7-45.4-43.9c-9.3-0.8-18.2,0.9-26.2,4.7l-20.8-40.1C330.4,198.3,329.5,193.4,330,188.8L330,188.8z" />
  </g>,
  "Telescope"
);

export default TelescopeIcon;
